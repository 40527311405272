'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.home
 # @description

###
angular
  .module 'mundoAdmin.home', [
    'ui.router'
  ]
